<template>
  <div>
    <!-- The header buttons -->
    <div class="d-flex justify-space-between">
      <!-- Show the go back button -->
      <v-btn
        text
        color="primary"
        :disabled="isMakingRequest"
        @click="$router.push({ name: 'CampaignTrackingInfluencers' })"
      >
        <v-icon left>
          arrow_back
        </v-icon>

        Influencers
      </v-btn>

      <div class="d-flex">
        <!-- Show the analytics button -->
        <v-btn
          text
          class="mr-3"
          color="primary"
          :disabled="isMakingRequest"
          @click="$router.push({ name: 'CampaignTrackingPosts' })"
        >
          <v-icon left>
            collections
          </v-icon>

          Posts
        </v-btn>

        <!-- Show the stories button -->
        <v-btn
          v-if="overview.model.platforms.includes('instagram')"
          text
          color="primary"
          :disabled="isMakingRequest"
          @click="$router.push({ name: 'CampaignTrackingStories' })"
        >
          <v-icon left>
            motion_photos_on
          </v-icon>

          Stories
        </v-btn>
      </div>
    </div>

    <!-- If we don't have any posts -->
    <div v-if="overview.categories.all[0].posts === 0">      <!-- Show the animation here -->
      <div style="max-width: 300px" class="mx-auto">
        <lottie-animation
          loop
          key="no-posts"
          file="98982-analytics.json"
        />
      </div>

      <div class="text-center">
        We'll prepare your analytics once we have your posts
      </div>
    </div>

    <!-- If we do have posts but we don't have any insights -->
    <div v-else>
      <!-- If we don't have all the insights unlocked -->
      <div
        v-if="overview.cost > 0"
        class="mb-16"
      >
        <div class="d-flex flex-wrap justify-space-between align-center white rounded box-shadow-soft px-3 px-md-6 py-4 mt-8">
          <!-- Show the text here -->
          <div v-if="overview.canUserWrite">
            You would need to spend {{ overview.cost }} {{ overview.cost > 1 ? "tokens" : "token" }} to unlock influencer insights and generate complete report!
          </div>

          <!-- Otherwise, show a message -->
          <div v-else>
            This analytics report is locked. Please ask the campaign owner to unlock it for you!
          </div>

          <!-- Show the button here -->
          <div>
            <v-btn
              depressed
              color="primary"
              :disabled="isMakingRequest || !overview.canUserWrite"
              @click="triggerUnlockRequest"
            >
              {{ isMakingUnlockRequest ? "View Progress" : "Unlock" }}

              <v-icon right>
                {{ isMakingUnlockRequest ? "hourglass_top" : "navigate_next" }}
              </v-icon>
            </v-btn>
          </div>
        </div>

        <div
          v-if="isMakingUnlockRequest && waitingInfluencers.length"
          :title="`${responseInfluencers.length}/${waitingInfluencers.length}!`"
          class="progress-bar-container"
        >
          <v-progress-linear
            color="primary"
            :buffer-value="progressValue + 10"
            :value="progressValue"
            stream
          />
        </div>
      </div>

      <!-- Otherwise, if we're making a request to fetch analytics overview -->
      <div v-else-if="isMakingAnalyzeRequest">
        <div style="max-width: 300px" class="mx-auto mx-auto">
          <lottie-animation
            loop
            key="loading-analytics"
            file="9844-loading-40-paperplane.json"
          />
        </div>
      </div>

      <!-- Finally, show the analytics report -->
      <div v-else-if="analytics" class="pt-6">
        <!-- Show the platforms selector here -->
        <!-- <div class="pb-4">
          <platform-selector :overview="overview" />
        </div> -->

        <!-- Show the overview value -->
        <overview
          :daywise="daywise"
          :overview="overview"
          :categories="categories"
          module="campaignTracking"
          show-annotations
        />

        <!-- Show the audience overview -->
        <audience
          :data="analytics"
          class="mt-6 mb-12"
        />

        <!-- Show form options for filter -->
        <div class="d-flex align-center justify-space-between my-4">
          <!-- Show an user preview here -->
          <div class="contain-select-width">
            <!-- Option to select the influencer here -->
            <profile-selector
              :overview="overview"
              label="Search Profile"
              type="search"
              dense
              flat
              solo
            />
          </div>

          <!-- Show a switch button -->
          <div class="d-flex align-center">
            Hide average values

            <v-switch
              v-model="shouldHideAverageValues"
              color="primary"
              class="ml-3 mt-0"
              hide-details
            ></v-switch>
          </div>
        </div>

        <!-- Use a child component -->
        <report
          class="mt-3"
          :overview="overview"
          :should-hide-average-values="shouldHideAverageValues"
        />
      </div>
    </div>

    <!-- To show a progress table -->
    <v-dialog
      v-model="shouldShowProgressTable"
      width="500"
    >
      <v-card>
        <v-card-text class="px-0 py-0">
          <!-- Show the table view -->
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
          >
            <!-- Modify the platform column -->
            <template v-slot:item.platform="{ item }">
              <div>
                <v-img
                  :src="`/img/socials/${item.platform}.svg`"
                  max-width="32"
                  height="32"
                ></v-img>
              </div>
            </template>

            <!-- Modify the influencer column -->
            <template v-slot:item.username="{ item }">
              <profile-chip
                :platform="item.platform"
                :data="item"
              />
            </template>

            <!-- Modify the status column -->
            <template v-slot:item.status="{ item }">
              <status-chip
                :status="item.status"
              />
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            depressed
            color="primary"
            @click="shouldShowProgressTable = false"
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"

// Import children components
const Report = () => import(/* webpackChunkName: "crm-report" */ "@/components/crm/Report")
const Audience = () => import(/* webpackChunkName: "crm-audience" */ "@/components/crm/Audience")
const Overview = () => import(/* webpackChunkName: "crm-overview" */ "@/components/crm/Overview")
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")
const ProfileSelector = () => import(/* webpackChunkName: "crm-profile-selector" */ "@/components/crm/ProfileSelector.vue")
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/components/crm/PlatformSelector.vue")

// The eventListener subscriptionId
const subscriptionId = Symbol("CampaignTracking/Analytics")

// Export the SFC
export default {
  // Name of the component
  name: "CampaignTrackingAnalytics",

  // Register children components
  components: {
    Report,
    Audience,
    Overview,
    StatusChip,
    ProfileChip,
    ProfileSelector,
    LottieAnimation,
    PlatformSelector
  },

  // Accept incoming data from parent
  props: {
    daywise: {
      type: Object,
      required: true
    },

    overview: {
      type: Object,
      required: true
    },

    categories: {
      type: Array,
      required: true
    },
  },

  // Define local data variables
  data: () => ({
    // Whether or not are we making a network request
    isMakingRequest: false,

    // Whether or not are we unlocking influencers
    isMakingUnlockRequest: false,

    // To track where the request originated from
    triggerId: null,
    // The list of influencers to be fetched
    waitingInfluencers: [],
    // The list of influencers that have been fetched
    responseInfluencers: [],

    // Whether or not are we fetching the report
    isMakingAnalyzeRequest: false,

    // Whether or not to show the progress table
    shouldShowProgressTable: false,

    // The selected influencer in report
    selectedInfluencer: null,
    // Whether or not to show the average values
    shouldHideAverageValues: false,

    // The table header items
    tableHeaders: [
      {
        text: "",
        value: "platform",
        sortable: false,
      },
      {
        text: "Influencer",
        value: "username",
        sortable: true
      },
      {
        text: "Status",
        value: "status",
        sortable: true
      }
    ],
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get the model data from Vuex store
     *
     * @returns {Object}
     */
    analytics() {
      return this.$store.getters["campaignTracking/analyticsById"](this.overview.model.id)
    },

    /**
     * Show the current unlock progress value
     *
     * @returns {Number}
     */
    progressValue() {
      return Math.floor(100 * (this.responseInfluencers.length / (this.waitingInfluencers.length || 1)))
    },

    /**
     * The table items to be shown
     *
     * @returns {Array}
     */
    tableItems() {
      // Map the waiting items to be shown
      return this.waitingInfluencers.map((item) => {
        // Compute the object
        const row = {
          ...item,
          status: "waiting"
        }

        // Check if we have an update for it
        const responseObject = this.responseInfluencers.find((search) => search.platform === item.platform && search.username === item.username)

        // If found
        if (responseObject) {
          // Update the status value
          row.status = responseObject.status
        }

        // Return the object
        return row
      })
    }
  },

  // Define local method functions
  methods: {
    /**
     * Get the analytics report for this campaign
     *
     * @returns {void}
     */
    async fetchAnalytics() {
      // If a request is already in queue
      if (this.isMakingAnalyzeRequest) {
        // Stop further execution
        return
      }

      // If there are no posts, don't bother fetching
      if (this.overview.categories.all[0].posts === 0) {
        // Stop further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.isMakingRequest = true
      this.isMakingAnalyzeRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Make a network request
      await this.$store.dispatch("campaignTracking/fetchAnalytics", this.overview.model.id)

      // Hide the loader
      this.isMakingRequest = false
      this.isMakingAnalyzeRequest = false
      this.$store.dispatch("loaders/remove", loaderId)
    },

    /**
     * Handle the message event about unlocking profiles
     *
     * @param {Object} data
     * @returns {void}
     */
    handleMessageEvent(data) {
      // Push the item to the list of unlocked accounts
      this.responseInfluencers.push({
        platform: data.localData.platform,
        username: data.localData.username,
        status: data.localData.status
      })

      // If the size matches, stop showing a loader
      if (this.responseInfluencers.length === this.waitingInfluencers.length) {
        // Hide the loader
        this.isMakingUnlockRequest = false

        // Fetch the analytics
        this.fetchAnalytics()

        // Also, refresh the user balance
        fetchProfile()
      }

      // The callback button for notifications
      const button = {
        text: "Check Progress",
        action: () => {
          this.shouldShowProgressTable = true
        }
      }

      // Depending on the status value
      switch (data.localData.status) {
        // When came across an error
        case "error":
          // Dispatch a notification
          this.$store.dispatch("notifications/add", {
            button,
            text: `Could not unlock insight for ${data.localData.platform}/@${data.localData.username}!`,
            type: "error",
            icon: "error",
            event: {
              module: "campaign-tracking",
              type: "unlock-influencer",
              key: "status-error"
            }
          })

          break

        // When came across a pending value
        case "pending":
          // Dispatch a notification
          this.$store.dispatch("notifications/add", {
            button,
            text: `Pending insight report for ${data.localData.platform}/@${data.localData.username}. Please try again in some minutes!`,
            type: "warning",
            icon: "downloading",
            event: {
              module: "campaign-tracking",
              type: "unlock-influencer",
              key: "status-pending"
            }
          })

          break

        // When came across a successful value
        case "success":
          // Dispatch a notification
          this.$store.dispatch("notifications/add", {
            button,
            text: `Fetched insight report for ${data.localData.platform}/@${data.localData.username}!`,
            type: "success",
            icon: "check_circle",
            event: {
              module: "campaign-tracking",
              type: "unlock-influencer",
              key: "status-success"
            }
          })

          // Also decrease the cost value
          this.$store.dispatch("campaignTracking/decreaseCost", this.overview.model.id)

          break
      }
    },

    /**
     * Trigger a network request to fetch
     *
     * @returns {void}
     */
    async triggerUnlockRequest() {
      // If already making a request
      if (this.isMakingUnlockRequest) {
        // Show the progress bar
        this.shouldShowProgressTable = true

        // Stop further execution
        return
      }

      // Check if the user has enough balance
      if (this.$store.getters["auth/availableCredits"] < this.overview.cost) {
        // Show the message
        this.$store.dispatch("toasts/add", { text: "You don't have enough credits!" })

        // Stop further execution
        return
      }

      // Otherwise check if there needs to be unlocked
      if (this.overview.cost === 0) {
        // Show the message
        this.$store.dispatch("toasts/add", { text: "There's no profile that needs to be unlocked" })

        // Stop further execution
        return
      }

      // Show a loader
      this.isMakingRequest = true
      this.isMakingUnlockRequest = true

      // Otherwise, make the network request
      try {
        // Set a new triggerId
        this.triggerId = String(Date.now())

        // Reset the values
        this.waitingInfluencers = []
        this.responseInfluencers = []

        // Use helper function
        const response = await axios({
          url: `/api/campaign-tracking/${this.overview.model.id}/unlock`,
          method: "POST",
          data: {
            clientId: getClientID(),
            triggerId: this.triggerId
          }
        })

        // If there's nothing being fetched
        if (response.data.length === 0) {
          // Stop showing a loader
          this.isMakingUnlockRequest = false

          // Show a message
          this.$store.dispatch("toasts/add", { text: "All insights have been unlocked!" })

          // Fetch the analytics
          this.fetchAnalytics()
        }
        // Otherwise
        else {
          // Update the waiting list
          this.waitingInfluencers = response.data

          // If request successful, show a message
          this.$store.dispatch("toasts/add", { text: "Please wait, it could take a few minutes..." })
        }
      }
      // Catch an error
      catch (error) {
        // Log it to the console
        logger({ type: "CampaignTracking/Unlock Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      }
      // Nonetheless
      finally {
        this.isMakingRequest = false
      }
    },
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // If we don't have the data yet, fetch it
    if (!this.analytics && this.overview.categories.all[0].posts > 0 && this.overview.cost === 0) {
      // Use method function
      this.fetchAnalytics()
    }
  },

  /**
   * As soon as the component has been rendered
   *
   * @returns {void}
   */
  mounted() {
    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "campaign-tracking",
      type: "all",
      key: "unlock-tracker-insight",
      validator: (event) => event.module === "campaign-tracking" && event.localData.clientId === getClientID() && event.localData.triggerId === this.triggerId,
      callback: this.handleMessageEvent
    })
  },

  /**
   * Right before the component is about to be destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Remove the message event listener
    messageEvents.deregister(subscriptionId)
  }
}
</script>
